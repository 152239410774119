<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" persistent max-width="800px">
      <v-card>
        <v-card-title>
           <v-card-title> {{ DialogMessage }}</v-card-title>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" sm="6">
                <v-menu offset-y>
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      label="Date  From"
                      readonly
                      v-on="on"
                      dense
                      v-model="editedItem.FromDate"
                      outlined
                    ></v-text-field>
                  </template>
                  <v-date-picker v-model="editedItem.FromDate"></v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="12" sm="6">
                <v-menu offset-y>
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      dense
                      label="Date To"
                      readonly
                      v-on="on"
                      outlined
                      v-model="editedItem.ToDate"
                    ></v-text-field>
                  </template>
                  <v-date-picker v-model="editedItem.ToDate"></v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field
                  dense
                  label="Discount"
                  outlined
                  type="number"
                  v-model="editedItem.Discount"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6">
                <v-checkbox
                  label="Auto Update"
                  type="Active"
                  v-model="editedItem.AutoUpdt"
                  required
                ></v-checkbox>
              </v-col>
            </v-row>
          </v-container>
          <small>*indicates required field</small>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="warning" @click="closeDialog"> Close </v-btn>
          <v-btn color="primary" @click="saveChanges"> Save </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
export default {
  props: {
    editedItem: {
      type: Object,
      required: true,
    },
   
    dialog: {
      type: Boolean,
      required: true,
    },
    DialogMessage: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      valid: true,
      priceList: [],
      Items: [],
      OsppItems: [],
      Uoms: [],
      spp1: {},
      spp2: {},
      UomEntries: [],
      itemsData: [],
      DiscTypes: [
        { text: "Discount(%)", value: 1 },
        { text: "Promotional Items", value: 2 },
      ],
    };
  },
  watch: {
    initial: {
      handler: "init",
      immediate: true,
    },
  },
  methods: {
    init(val) {
      this.record = { ...val };
    },
    closeDialog() {
      this.$emit("close");
    },
    saveChanges() {
      console.log(this.editedItem);
      this.$emit("save", this.editedItem);
    },
    addField() {
      this.editedItemFields.push({ value: "" });
    },
    removeField(index) {
      this.editedItemFields.splice(index, 1);
    },

    updatePrice() {
      //   this.record.Price = "";
      //   let UomEntry = this.record.UomEntry || 0;
      //   if (UomEntry != 0) {
      //     let uomData = this.Uoms.find(function (item) {
      //       return item.UomEntry == UomEntry;
      //     });
      //     let discount = this.record.Discount || 0;
      //     let discountAmt = uomData.Price * (discount / 100);
      //     this.record.Price = (uomData.Price - discountAmt).toFixed(2);
      //   }
    },
    getUomPrices() {
      const self = this;
      this.loading = true;
      this.$store
        .dispatch("get", `/uom-prices/${this.$route.params.ItemCode}`)
        .then((res) => {
          self.Uoms = res.ResponseData;

          self.loading = false;
        })
        .catch((err) => {
          this.$refs.snackbar.show(err, "red");
        });
    },
    getUoms() {
      const self = this;
      this.loading = true;
      this.$store
        .dispatch("get", `/uom`)
        .then((res) => {
          self.UomEntries = res.ResponseData;

          self.loading = false;
        })
        .catch((err) => {
          this.$refs.snackbar.show(err, "red");
        });
    },
    getItems() {
      const self = this;
      this.loading = true;
      this.$store
        .dispatch("get", `/items_data`)
        .then((res) => {
          self.itemsData = res.ResponseData;
          self.loading = false;
        })
        .catch((err) => {
          this.$refs.snackbar.show(err, "red");
        });
    },
  },
  created() {
    this.getUomPrices();
    this.getUoms();
    this.getItems();
  },
};
</script>