<template>
  <v-container fluid>
    <v-card>
      <v-toolbar color="secondary" flat>
        Period Discounts
        <v-spacer></v-spacer>
        <v-btn color="success" small @click="editItem(editedItem, 1)">
          Add Item
        </v-btn>
        <v-btn color="primary" small text @click="goBack">
          <v-icon left dark>mdi-keyboard-backspace</v-icon>Back
        </v-btn>
      </v-toolbar>
      <v-card-text>
        <v-row dense>
          <v-col cols="12" sm="12" md="12">
            <v-text-field dense v-model="search" label="Search"></v-text-field>
            <v-data-table
              dense
              :loading="loading"
              loading-text="Loading... Please wait"
              :headers="headers"
              :items="OsppItems"
              :search="search"
              small
            >
            <template v-slot:item.created_at="{ item }">{{
                item.created_at | moment("LLL")
              }}</template>
              <template v-slot:item.AutoUpdt="{ item }">
                <v-checkbox dense small v-model="item.AutoUpdt"> </v-checkbox>
              </template>
              <template v-slot:item.id="{ item }">
                <v-btn
                  color="accent"
                  text
                  :to="`/inventory/volume-discounts/create/${item.id}`"
                  ><v-icon color="success">mdi-plus-circle</v-icon></v-btn
                >
                <!-- <v-btn @click="activateDialog(item)" icon>
                  <v-icon color="black">mdi-pencil-circle-outline</v-icon>
                </v-btn> -->
                   <v-btn @click="editItem(item, 2)" color="light" small>
                  <v-icon>mdi-pencil</v-icon>
                </v-btn>
                <v-btn @click="removeItem(item.id)" color="white" plain small>
                  <v-icon color="red"> mdi-delete-circle-outline</v-icon>
                </v-btn>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
        <edit-dialog
          :editedItem="editedItem"
          :dialog="dialog"
          :DialogMessage="DialogMessage"
          @close="dialog = false"
          @save="SavePeriodDiscount"
        ></edit-dialog>
      </v-card-text>
      <snackbar ref="snackbar"></snackbar>
    </v-card>
  </v-container>
</template>
       

<script>
import EditDialog from "./_components/editor.vue";

export default {
  components: {
    EditDialog,
  },
  data: () => ({
    editedItem: {},
    search: "",
    priceList: [],
    Items: [],
    OsppItems: [],
    Uoms: [],
    record: {},
    spp2: {},
    UomEntries: [],
    itemsData: [],
    dialog: false,
    VdiscountDialog: false,
    PickedPriceList: "",
    SPP1LNum: "",
    DialogMessage: "Add Item",
    headers: [
      { text: "Valid From.", value: "FromDate" },
      { text: "Valid To", value: "ToDate" },
      { text: "Price List", value: "ListNum" },
      { text: "Discount %", value: "Discount" },
      // { text: "Price After Dicount", value: "" },
      { text: "Auto", value: "AutoUpdt" },
      { text: "Created On", value: "created_at" },
      { text: "Action", value: "id" },
    ],
    spp2Items: [],
  }),
  methods: {
    addField() {
      this.fields.push({ value: "" });
    },
    removeField(index) {
      this.fields.splice(index, 1);
    },
    getItemPeriodDiscount() {
      const self = this;
      this.loading = true;
      this.$store
        .dispatch(
          "get",
          `period-discounts/?ListNum=${this.$route.params.ListNum}&ItemCode=${this.$route.params.ItemCode}`
        )
        .then((res) => {
          self.Items = res.ResponseData;
          self.OsppItems = res.ResponseData.filter(
            (item) => item.ospp !== null
          );

          self.loading = false;
        })
        .catch((err) => {
          this.$refs.snackbar.show(err, "red");
        });
    },

    SavePeriodDiscount(data) {
      let item = data;
      console.log("data", data);
      if (this.action == 1) {
        this.save(item);
      } else {
        this.update(item);
      }
    },
    save(item) {
      const url = `/period-discounts`;
      const self = this;
      item.ListNum = this.$route.params.ListNum;
      item.ItemCode = this.$route.params.ItemCode;
      item.LINENUM = this.$route.params.LINENUM;
      const data = item;
      self.$store
        .dispatch("post", { url, data })
        .then(() => {
          this.dialog = false;
          item = {};
          this.getItemPeriodDiscount();
        })
        .catch((err) => {
          console.log(err, "err");
          this.dialog = false;
          // this.$refs.snackbar.show(err, "red");
        });
    },
    update(item) {
      const url = `/period-discounts/${this.editedItem.id}`;
      const self = this;
      item.ListNum = this.$route.params.ListNum;
      item.ItemCode = this.$route.params.ItemCode;
      item.LINENUM = this.$route.params.LINENUM;
      const data = item;
      self.$store
        .dispatch("put", { url, data })
        .then(() => {
          this.dialog = false;
          item = {};
          this.getItemPeriodDiscount();
        })
        .catch((err) => {
          console.log(err, "err");
          this.dialog = false;
          // this.$refs.snackbar.show(err, "red");
        });
    },
    removeVolDiscount(item) {
      console.log(item.id);
      const url = `/volume-discounts/${item.id}`;
      const self = this;

      self.$store
        .dispatch("remove", url)
        .then(() => {
          this.dialog = false;

          this.getItemPeriodDiscount();
        })
        .catch((err) => {
          console.log(err, "err");
          this.dialog = false;
          this.$refs.snackbar.show(err, "red");
        });
    },
    getUomPrices() {
      const self = this;
      this.loading = true;
      this.$store
        .dispatch("get", `/uom-prices/${this.$route.params.ItemCode}`)
        .then((res) => {
          self.Uoms = res.ResponseData;

          self.loading = false;
        })
        .catch((err) => {
          this.$refs.snackbar.show(err, "red");
        });
    },
    getUoms() {
      const self = this;
      this.loading = true;
      this.$store
        .dispatch("get", `/uom`)
        .then((res) => {
          self.UomEntries = res.ResponseData;

          self.loading = false;
        })
        .catch((err) => {
          this.$refs.snackbar.show(err, "red");
        });
    },
    getItems() {
      const self = this;
      this.loading = true;
      this.$store
        .dispatch("get", `/items_data`)
        .then((res) => {
          self.itemsData = res.ResponseData;
          self.loading = false;
        })
        .catch((err) => {
          this.$refs.snackbar.show(err, "red");
        });
    },

    editItem(item, action) {
      console.log(item);

      if (action == 2) {
        this.action = 2;
        this.DialogMessage = "Edit Item";
        this.editedItem = Object.assign({}, item);
      } else {
        this.action = 1;
        this.DialogMessage = "Add Item";
        this.editedItem = {};
      }
      if (item.fields) {
        this.editedItemFields = item.fields;
      } else {
        this.editedItemFields = [];
      }
      this.dialog = true;
    },

    removeItem(id) {
      var userResponse = confirm(
        "Are you sure you want to delete? This action cannot be undone."
      );
      if (userResponse === true) {
        const url = `/period-discounts/${id}`;
        const self = this;
        self.$store
          .dispatch("remove", url)
          .then(() => {
            this.getItemPeriodDiscount();
          })
          .catch((err) => {
            console.log(err, "err");

            this.$refs.snackbar.show(err, "red");
          });
      } else {
        alert("Operation Cancelled.");
      }
    },
    activateDialog(item) {
      this.record = item;
      this.dialog = true;
    },
    goBack() {
      this.$router.go(-1);
    },
  },
  created() {
    this.getItemPeriodDiscount();
    this.getUomPrices();
    this.getUoms();
    this.getItems();
  },
};
</script>